<template>
  <section class="specialists-slider">
    <div class="wrapper">
      <h2 class="specialists-slider__title">{{ title }}</h2>
      <ClientOnly>
        <Swiper v-bind="swiperSettings" class="specialists-slider__slider">
          <SwiperSlide
            v-for="(doctor, index) in doctors"
            :key="index"
            class="specialists-slider__slider-slide"
          >
            <CSpesialistCard
              :id="doctor.id"
              :preview_text="doctor.description"
              :link="doctor.link"
              :image_seo="doctor.image_seo"
              :image="doctor.image"
              :full_slug="doctor.full_slug"
              :full_name="doctor.full_name"
              :experience_from="doctor.experience_from"
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
  </section>
</template>

<script setup lang="ts">
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import CSpesialistCard from '~/components/blocks/specialistCard/CSpesialistCard.vue';
import type { BlockDoctorsCard } from '~/types/pages';

interface Props {
  title: string;
  doctors: BlockDoctorsCard[];
}

defineProps<Props>();

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 16,
  createElements: true,
  autoHeight: false,
  navigation: true,
  pagination: true,
  modules: [Pagination, Navigation],
  breakpoints: {
    1025: {
      slidesPerView: 3,
      spaceBetween: 32,
      centeredSlides: false,
    },
    769: {
      slidesPerView: 3,
      spaceBetween: 24,
      centeredSlides: false,
    },
    475: {
      slidesPerView: 2,
      spaceBetween: 16,
      centeredSlides: false,
    },
  },
};
</script>

<style scoped></style>
